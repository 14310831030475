import React from "react"
import Layout from "../../components/layout"
import { Container, Row, Col, Alert } from "reactstrap"
import { StaticImage } from 'gatsby-plugin-image'
import 'bootstrap/dist/css/bootstrap.css'


let P3 = () => (
    <Layout>
        <Container className="py-5">
            <Row>
                <Col xs={12} md={3} className="mb-3">
                    <StaticImage src="../../data/product/p6.jpg" />
                    <div style="line-height: 30px;"><span style="font-size: 16px;">道口警示灯
                    </span><br style="" /></div>
                </Col>
                <Col xs={12} md={9} className="mb-9">
                    <Alert color="primary">
                        产品详情
                    </Alert>
                    <p>
                    道口警示牌安装在道口房公路两侧远端，用于提醒行人车辆通过道口时注意慢行，平时显示黄色字体闪亮，火车通过时显示红色字体闪亮。
                    </p>
                </Col>
            </Row>
        </Container>
    </Layout>
)

export default P3





